import { sendGAEvent } from "@next/third-parties/google";
import { Adb, AdbDaemonDevice, AdbPacketData } from "@yume-chan/adb";
import { action, makeAutoObservable, observable } from "mobx";

export type PacketLogItemDirection = "in" | "out";

export interface PacketLogItem extends AdbPacketData {
    direction: PacketLogItemDirection;

    timestamp?: Date;
    commandString?: string;
    arg0String?: string;
    arg1String?: string;
    payloadString?: string;
}

export class GlobalState {
    device: AdbDaemonDevice | undefined = undefined;
    deviceDetails: {
        width: number;
        height: number;
        model: string;
        productName: string;
    } = {
        width: 0,
        height: 0,
        model: "",
        productName: "",
    };
    deviceList: AdbDaemonDevice[] = [];
    adb: Adb | undefined = undefined;

    errorDialogVisible = false;
    errorDialogMessage = "";
    errorDialogWithCloseButton = false;

    logs: PacketLogItem[] = [];

    constructor() {
        makeAutoObservable(this, {
            hideErrorDialog: action.bound,
            logs: observable.shallow,
        });
    }

    async setDevice(device: AdbDaemonDevice | undefined, adb: Adb | undefined) {
        if (device && adb) {
            // init screen size
            const windowSizeAdbResponse = await adb.subprocess.spawnAndWait(
                "wm size",
            );

            const regex = /(\d+)x(\d+)/; // Captures output like "Physical size: 1080x1920"

            const match = windowSizeAdbResponse.stdout?.match(regex);
            if (match) {
                const width = parseInt(match[1], 10);
                const height = parseInt(match[2], 10);

                this.deviceDetails.width = width;
                this.deviceDetails.height = height;
            }

            // init model
            const model = await adb.subprocess.spawnAndWait(
                "getprop ro.product.model",
            );
            this.deviceDetails.model = model.stdout?.trim();

            // init productName
            const productName = await adb.subprocess.spawnAndWait(
                "getprop ro.product.name",
            );
            this.deviceDetails.productName = productName.stdout?.trim();

            console.log("init device details", this.deviceDetails);
        } else {
            this.deviceDetails = {
                width: 0,
                height: 0,
                model: "",
                productName: "",
            };
        }

        this.device = device;
        this.adb = adb;
    }

    setDeviceList(deviceList: AdbDaemonDevice[]) {
        this.deviceList = deviceList;
    }

    showErrorDialog(message: Error | string) {
        this.errorDialogVisible = true;
        if (message instanceof Error) {
            this.errorDialogMessage = message.stack || message.message;
        } else {
            this.errorDialogMessage = message;
        }
        sendGAEvent("event", "show_error", {
            message: this.errorDialogMessage,
        });
    }

    hideErrorDialog() {
        this.errorDialogVisible = false;
        this.errorDialogWithCloseButton = false;
    }

    closeWindowFromErrorDialog() {
        window.close();
        this.errorDialogVisible = false;
        this.errorDialogWithCloseButton = false;
    }

    appendLog(direction: PacketLogItemDirection, packet: AdbPacketData) {
        this.logs.push({
            ...packet,
            direction,
            timestamp: new Date(),
            payload: packet.payload.slice(),
        } as PacketLogItem);
    }

    clearLog() {
        this.logs.length = 0;
    }
}

export const GLOBAL_STATE = new GlobalState();
